import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalBaseComponent } from '@modal/components/modal-base/modal-base.component';

@Component({
	selector: 'app-modal-prize-description',
	standalone: true,
	imports: [],
	templateUrl: './modal-prize-description.component.html',
	styleUrl: './modal-prize-description.component.scss',
})
export class ModalPrizeDescriptionComponent extends ModalBaseComponent {
	@Input() static = false;
	@Output() nextTemplate = new EventEmitter<void>();

	close() {
		if (!this.static) {
			this.closeModal();
		} else {
			this.nextTemplate.emit();
		}
	}
}
