import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { StateService } from '@services/state.service';

export const levelGuard: CanActivateFn = (
	route,
	state,
	gameState = inject(StateService)
) => {
	const level = Number(route.params['level']);
	return level <= gameState.levelId;
};
