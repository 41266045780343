export interface LevelConfig {
	level: number;
}
const spriteMap: { [key: string]: string } = {
	'1': 'M6000 402C6000 402 5792 410 5661.68 379.56C5531.36 349.12 5446 333.98 5280.08 414.3C5114.16 494.62 4954 378 4768 320C4582 262 4486 148 4324 148C4162 148 4190 227 4056 227C3922 227 3946 166 3840 166C3734 166 3624 350 3418 298C3212 246 3266 20 3000 20C2734 20 2424 382 2238 308C2052 234 2086 107 1886 107C1686 107 1624 206 1566 272C1508 338 1296 628.02 1094 557.4C892 486.78 994 188 868 188C742 188 642 402 457 402H0',
	'2': 'M0 554.66C283.76 554.66 504 524.66 700 340.66C896 156.66 952 424.66 1064 488.66C1176 552.66 1256 412.66 1328 336.66C1400 260.66 1528 251.16 1528 415.9C1528 580.64 1596 588.66 1696 504.66C1796 420.66 1864 369.66 1960 417.16C2056 464.66 2068 532.66 2216 532.66C2364 532.66 2404.14 312.66 2584 316.66C2763.86 320.66 2748 472.66 2888 508.66C3028 544.66 3020 468.66 3104 452.66C3188 436.66 3252 485.96 3380 609.3C3508 732.64 3672 524.66 3788 388.66C3904 252.66 4088 -67.3398 4268 12.6602C4448 92.6602 4480 280.66 4660 468.66C4840 656.66 4956 330.28 5092 323.46C5228 316.64 5217.16 484.66 5334.58 508.66C5452 532.66 5518.26 508.34 5585.14 418.5C5652.02 328.66 5760 348.66 5840 476.66C5920 604.66 6108 644.66 6204 564.66C6300 484.66 6492 284.66 6704 304.66C6916 324.66 7136 656.66 7212 732.66C7288 808.66 7424.46 721.9 7540 609.3C7655.54 496.7 7824 408.66 8000 408.66',
	'3': 'M0 469.415C275.8 469.415 413.64 474.355 662.82 436.875C912 399.415 1080 283.414 1288 163.414C1496 43.4145 1616 267.415 1864 387.415C2112 507.415 2280 555.414 2616 547.414C2952 539.414 3091.78 100.094 3368 83.4145C3516.08 74.4745 3549.36 81.9745 3633.78 175.894C3696.9 246.114 3731.56 260.334 3777.78 259.454C3902.02 257.074 3908 219.415 3988 339.415C4068 459.415 4132 527.415 4240 467.415C4348 407.415 4350.6 246.294 4508 243.414C4665.4 240.534 4680 431.415 4764 447.415C4848 463.415 5020 503.415 5124 347.415C5228 191.415 5356 275.415 5436 391.415C5516 507.415 5704 431.415 5832 259.415C5960 87.4145 6124 -114.946 6396 82.2345C6668 279.414 6663.56 432.074 6919.78 401.734C7176 371.394 7340 59.3945 7744 111.394C8148 163.394 8196 579.395 8592 647.395C8988 715.395 9420.36 452.355 9544.18 407.875C9668 363.395 9796 473.395 10000 473.395',
	'4': 'M0 401.78C0 401.78 174.66 411.32 279.84 392.88C385.02 374.44 458.68 314.44 552 245.12C645.32 175.8 717.34 226.46 768 301.12C818.66 375.78 877.34 397.12 957.34 327.78C1037.34 258.44 1077.34 175.78 1213.34 285.12C1349.34 394.46 1392 410.46 1530.68 386.46C1669.34 362.46 1680.02 258.46 1760.02 242.46C1840.02 226.46 1888.02 250.46 1968.02 357.12C2048.02 463.78 2141.36 547.26 2269.36 509.52C2397.36 471.78 2410.7 232.68 2530.7 212.24C2650.7 191.8 2709.36 215.8 2792.04 341.12C2874.72 466.44 3026.7 437.12 3144.04 317.12C3261.38 197.12 3402.66 33.3801 3525.34 23.2401C3648.02 13.1001 3776 181.1 3874.68 311.78C3973.36 442.46 4112.02 490.44 4277.34 426.44C4442.68 362.44 4456 197.1 4688 178.44C4920 159.78 4981.34 394.44 5146.66 442.44C5311.98 490.44 5408 410.44 5506.66 330.44C5605.32 250.44 5690.66 149.1 5800 213.1C5909.34 277.1 5850.66 567.76 5960 581.1C6069.34 594.44 6101.34 525.1 6162.66 450.44C6223.98 375.78 6293.32 330.44 6418.66 423.78C6544 517.12 6696 522.44 6805.32 474.44C6914.64 426.44 7050.66 191.78 7119.98 82.4401C7189.3 -26.8999 7346.64 -45.5599 7437.32 138.44C7528 322.44 7846.2 425.64 7995.1 354.04C8144 282.44 8186.66 287.78 8248 322.44C8309.34 357.1 8336 557.1 8429.34 599.78C8522.68 642.46 8784 397.34 8896 299.88C9008 202.42 9178.66 10.4401 9413.34 42.4401C9648.02 74.4401 9715.66 374.92 9896.5 375.34C10077.3 375.76 10152 351.76 10250.7 271.76C10349.4 191.76 10472 125.1 10605.3 226.42C10738.7 327.74 10765.3 354.42 10874.7 415.76C10984 477.1 11093.3 389.9 11213.3 509.5C11333.3 629.1 11514.7 595.24 11578.7 525.5C11642.7 455.76 11757.3 302.34 12000 302.34',
	'5': 'M14000 520.619C14000 520.619 13708 592.619 13648 572.619C13588 552.619 13576 496.619 13476 492.619C13376 488.619 13316 604.619 13204 608.619C13092 612.619 13124 456.619 13112 364.619C13100 272.619 13040 184.619 12928 180.619C12816 176.619 12736 328.619 12660 331.099C12584 333.579 12532 356.619 12472 420.619C12412 484.619 12412 488.619 12292 496.619C12172 504.619 12180 652.639 12024 650.359C11868 648.079 11628 484.619 11544 424.619C11460 364.619 11324 408.619 11280 472.619C11236 536.619 11084 708.639 10928 650.359C10772 592.079 10720 456.619 10548 356.619C10376 256.619 10304 396.619 10184 464.619C10064 532.619 9976 524.619 9812 380.619C9648 236.619 9628 328.619 9424 352.619C9220 376.619 9203.1 250.199 9100 164.079C8996.9 77.9593 8836 156.619 8788 236.619C8740 316.619 8672 392.619 8584 372.619C8496 352.619 8516 340.619 8404 376.619C8292 412.619 8320 544.619 8192 568.619C8064 592.619 7988 596.619 7848 436.619C7708 276.619 7660 320.619 7520 320.139C7380 319.659 7368 336.619 7212 128.619C7056 -79.3807 6880 -11.3807 6776 164.079C6672 339.539 6540 584.619 6304 448.679C6068 312.739 5884 408.619 5716 548.619C5548 688.619 5332 644.619 5212 572.619C5092 500.619 5036 376.619 4764 376.619C4492 376.619 4384 480.619 4272 572.619C4160 664.619 3996 648.619 3920 524.619C3844 400.619 3715.14 230.279 3532 260.619C3348.86 290.959 3320 476.619 3128 472.619C2936 468.619 2879.66 483.679 2724 344.539C2568.34 205.399 2412 328.619 2348 432.619C2284 536.619 2132 680.619 1984 576.619C1836 472.619 1836 340.619 1604 344.619C1372 348.619 1332 452.619 1184 568.619C1036 684.619 872 572.619 812 482.139C752 391.659 680.02 300.639 520.3 421.659C360.58 542.679 192.14 542.639 0 542.639V542.619',
};

/**
 * Получает ключ спрайта дороги на основе конфигурации уровня.
 * @param config Объект с номером уровня и дороги.
 * @returns Строка с именем спрайта.
 */
export function getRoadSprite(config: LevelConfig): string {
	const { level } = config;
	const key = `${level}`;
	const sprite = spriteMap[key];
	if (!sprite) {
		console.warn(
			`Спрайт для уровня ${level} не найден. Используется спрайт по умолчанию.`
		);
		return 'M6000 402C6000 402 5792 410 5661.68 379.56C5531.36 349.12 5446 333.98 5280.08 414.3C5114.16 494.62 4954 378 4768 320C4582 262 4486 148 4324 148C4162 148 4190 227 4056 227C3922 227 3946 166 3840 166C3734 166 3624 350 3418 298C3212 246 3266 20 3000 20C2734 20 2424 382 2238 308C2052 234 2086 107 1886 107C1686 107 1624 206 1566 272C1508 338 1296 628.02 1094 557.4C892 486.78 994 188 868 188C742 188 642 402 457 402H0'; // Убедитесь, что у вас есть спрайт по умолчанию
	}
	return sprite;
}

export const coefficientMap: Record<number, number> = {
	1: 40000,
	2: 60000,
	3: 75000,
	4: 90000,
	5: 100000,
};
