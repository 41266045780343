// PreloadScene.ts
import Phaser from 'phaser';
import Progressbar from '@src/app/ts/models/progressbar';

export default class PreloadScene extends Phaser.Scene {
	private assetsPath = 'assets/game-resources/';
	private progressBar: Progressbar;
	private backgroundImage: Phaser.GameObjects.Image;

	constructor() {
		super({ key: 'PreloadScene' });
	}

	preload() {
		// Добавление фонового изображения прелоадера
		this.backgroundImage = this.add.image(
			this.cameras.main.centerX,
			this.cameras.main.centerY,
			'preloader-bg'
		);
		this.backgroundImage.setOrigin(0.5, 0.5);
		this.backgroundImage.setScale(1.3);

		// Создание экземпляра Progressbar
		const maxWidth = this.cameras.main.width * 0.8; // 80% ширины экрана
		const x = this.cameras.main.centerX - maxWidth / 2;
		const y = this.cameras.main.height / 5; // Позиция под фоном
		const totalDistance = 1; // Процент от 0 до 1

		this.progressBar = new Progressbar(this, x, y, totalDistance);

		// Слушаем события загрузки для обновления прогресс-бара
		this.load.on('progress', this.updateProgressBar, this);
		// this.load.on('complete', this.completeLoading, this);

		// Загрузка игровых ассетов
		const level = this.game.registry.get('level');

		this.load.image('backwards', this.assetsPath + 'left-pedal.png');
		this.load.image('gas', this.assetsPath + 'right-pedal.png');
		this.load.image('car', this.assetsPath + 'car.png');
		this.load.image('wheel', this.assetsPath + 'wheel.png');
		this.load.image('restart-btn', this.assetsPath + 'restart-btn.png');
		this.load.image('restart-bg', this.assetsPath + 'restart-bg.png');
		this.load.image('arrow', this.assetsPath + 'speed-arrow.png');
		this.load.image('speed', this.assetsPath + 'speed.png');
		// this.load.image('road', this.assetsPath + `levels/road-bg-${level}.png`);
		// this.load.image(
		// 	'level',
		// 	this.assetsPath + `levels/level-bg-${level}-full.png`
		// );
		this.preloadBackgroundTiles(level);
		this.load.image('coin', this.assetsPath + `fonbet-coin.svg`);
		this.load.image('counter-bg', this.assetsPath + `counter-bg.svg`);
		this.load.image('time-bg', this.assetsPath + `time-bg.png`);
		this.load.image('time', this.assetsPath + `time.svg`);
	}

	private preloadBackgroundTiles(level: number) {
		console.log(`Загрузка тайлов для текущего уровня: ${level}`);
		let tileCols: number;
		switch (level) {
			case 2:
				tileCols = 8;
				break;
			case 3:
				tileCols = 10;
				break;
			case 4:
				tileCols = 12;
				break;
			case 5:
				tileCols = 14;
				break;

			default:
				tileCols = 6;
		}
		// Загрузка тайлов для текущего уровня
		const tileRows = 4; // Количество строк в сетке
		const totalTiles = tileRows * tileCols; // 24 тайла

		for (let index = 1; index <= totalTiles; index++) {
			const tileKey = `tile_${index}`;
			const tilePath = `${this.assetsPath}levels/level_${level}/${index}.png`;
			this.load.image(tileKey, tilePath);
		}
	}

	create() {
		// Начало игровых сцен после завершения загрузки
  this.completeLoading();
	}

	private updateProgressBar(progress: number) {
		if (this.progressBar) {
			this.progressBar.update(progress); // progress от 0 до 1
		}
	}

	private completeLoading() {
		// Эмитируем событие для отслеживания в Angular
		this.sys.game.events.emit('loadingComplete');

		console.log('loadingComplete');
		// Переход к игровым сценам
		this.scene.start('MainScene');
		this.scene.start('GuiScene');
	}
}
