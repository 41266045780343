import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FonBetWidgetComponent } from '@components/fon-bet-widget/fon-bet-widget.component';
import { StateService } from '@services/state.service';
import { ApiService } from '@services/api.service';
import { delay, take } from 'rxjs';

@Component({
	selector: 'app-fonbet-page',
	standalone: true,
	imports: [RouterLink, FonBetWidgetComponent],
	templateUrl: './fonbet-page.component.html',
	styleUrl: './fonbet-page.component.scss',
})
export class FonbetPageComponent implements OnInit {
	private state = inject(StateService);
	private api = inject(ApiService);

	ngOnInit() {
		this.state.userRegistration = false;
		this.api.sendUserData(this.state.user).subscribe();
	}
}
