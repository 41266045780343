// BootScene.ts
import Phaser from 'phaser';

export default class BootScene extends Phaser.Scene {
	private assetsPath = 'assets/game-resources/';

	constructor() {
		super({ key: 'BootScene' });
	}

	preload() {
		// Загрузка ассетов прелоадера
		this.load.image('preloader-bg', this.assetsPath + 'preloader-bg.png');
		this.load.image('progress-bg', this.assetsPath + 'progress-bg.png');
		this.load.image('progress-icon', this.assetsPath + 'progress-icon.svg');
	}

	create() {
		// Переход к сцене загрузки игровых ассетов
		this.scene.start('PreloadScene');
	}
}
