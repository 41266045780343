import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { ApiService } from '@services/api.service';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-save-form-page',
	standalone: true,
	imports: [RouterLink, ReactiveFormsModule, NgClass, NgxMaskDirective],
	templateUrl: './save-form-page.component.html',
	styleUrl: './save-form-page.component.scss',
})
export class SaveFormPageComponent {
	public form: FormGroup;
	constructor(
		private fb: FormBuilder,
		private router: Router,
		private api: ApiService,
		private state: StateService
	) {
		this.form = this.fb.group({
			fio: [null, [Validators.required, Validators.minLength(3)]],
			phone: [
				null,
				[
					Validators.required,
					Validators.minLength(11),
					Validators.maxLength(11),
				],
			],
			policy: [true, [Validators.requiredTrue]],
		});
	}

	get nameControl(): AbstractControl {
		return this.form.get('fio');
	}

	get telephoneControl(): AbstractControl {
		return this.form.get('phone');
	}
	onSubmit() {
		const { fio, phone } = this.form.value;
		this.state.userInfo = { fio, phone };
		this.router.navigate(['/registration']);
	}
}
