// terrain.ts
import Phaser from 'phaser';
import { svgPathProperties } from 'svg-path-properties';

export default class Terrain {
	private scene: Phaser.Scene;
	public totalLength: number;
	public x: number;
	public y: number;
	public height: number;
	public padding = 200;
	public normalizedVertices: { x: number; y: number }[];
	// public background: Phaser.GameObjects.Image; // Добавляем поле для фона
	public background: Phaser.GameObjects.RenderTexture;
	public level = 1;
	constructor(
		scene: Phaser.Scene,
		path: string,
		x: number,
		y: number,
		level: number
	) {
		this.scene = scene;
		this.x = x;
		this.y = y;
		this.level = level;

		this.adjustPadding();
		this.createBackground();
		this.createRoad(path);
	}

	private adjustPadding() {
		switch (this.level) {
			case 1:
				this.padding = 20;
				break;
			case 2:
			case 5:
				this.padding = 100;
				break;
			case 3:
				this.padding = 60;
				break;
			case 4:
				this.padding = 30;
				break;
		}
	}

	private createBackground() {
		// Параметры сетки тайлов
		let tileCols: number;
		switch (this.level) {
			case 2:
				tileCols = 8;
				break;
			case 3:
				tileCols = 10;
				break;
			case 4:
				tileCols = 12;
				break;
			case 5:
				tileCols = 14;
				break;

			default:
				tileCols = 6;
		}
		const tileRows = 4; // Количество строк
		const totalTiles = tileRows * tileCols; // 24 тайла

		// Размеры тайлов (замените на реальные размеры ваших тайлов)
		const tileWidth = 1000; // Ширина одного тайла
		const tileHeight = 652; // Высота одного тайла

		// Общие размеры фонового изображения
		const totalWidth = tileCols * tileWidth;
		const totalHeight = tileRows * tileHeight;

		// Создаем RenderTexture
		this.background = this.scene.add.renderTexture(
			this.x,
			this.y,
			totalWidth,
			totalHeight
		);

		// Сборка тайлов на RenderTexture
		let tileIndex = 1;
		for (let row = 0; row < tileRows; row++) {
			for (let col = 0; col < tileCols; col++) {
				const tileKey = `tile_${tileIndex}`;
				const x = col * tileWidth;
				const y = row * tileHeight;
				this.background.draw(tileKey, x, y);
				tileIndex++;
			}
		}

		// Позиционирование и центрирование RenderTexture
		this.background.setOrigin(0, 0.5);
		this.background.setPosition(this.x, this.y + this.padding);

		// Устанавливаем глубину, чтобы фон был за другими объектами
		this.background.setDepth(1);
	}

	private createRoad(path: string) {
		// @ts-ignore
		const Matter = Phaser.Physics.Matter.Matter;

		// Парсим SVG-путь с помощью svg-path-properties
		const properties = new svgPathProperties(path);
		const totalLength = properties.getTotalLength();
		this.totalLength = totalLength;
		const PATH_LENGTH = 200; // Количество точек для генерации
		const step = totalLength / PATH_LENGTH;
		const vertices: { x: number; y: number }[] = [];

		for (let i = 0; i <= PATH_LENGTH; i++) {
			const point = properties.getPointAtLength(i * step);
			vertices.push({ x: point.x, y: point.y });
		}

		// Дополнительные точки для закрытия формы
		const minMax = (items: number[]) => {
			return items.reduce(
				(acc, val) => {
					acc.high = isNaN(acc.high) || val > acc.high ? val : acc.high;
					acc.low = isNaN(acc.low) || val < acc.low ? val : acc.low;
					return acc;
				},
				{ high: NaN, low: NaN }
			);
		};

		let points = {
			x: minMax(vertices.map(point => point.x)),
			y: minMax(vertices.map(point => point.y)),
		};

		// Добавляем дополнительные точки для закрытия формы
		vertices.push({
			x: vertices[vertices.length - 1].x,
			y: vertices[vertices.length - 1].y + points.y.high + 500,
		});
		vertices.unshift({
			x: vertices[0].x,
			y: vertices[0].y + points.y.high + 500,
		});

		// Нормализуем вершины
		points = {
			x: minMax(vertices.map(point => point.x)),
			y: minMax(vertices.map(point => point.y)),
		};

		const normalizeVertices = (vertices: { x: number; y: number }[]) => {
			return vertices.map(point => {
				return { x: point.x - points.x.low, y: point.y - points.y.low };
			});
		};

		const normalizedVertices = normalizeVertices(vertices);
		this.normalizedVertices = normalizedVertices;
		const WIDTH = points.x.high - points.x.low;
		const HEIGHT = points.y.high - points.y.low;

		// Создание физического тела территории
		let terrainBody: any = this.scene.matter.add.fromVertices(
			WIDTH / 2,
			HEIGHT / 2,
			[normalizedVertices],
			{
				label: 'terrain',
				isStatic: true,
				friction: 1,
				restitution: 0,
			},
			true,
			0.05,
			1
		);

		// Корректировка позиции центра масс
		let centerOfMass = Matter.Vector.sub(
			terrainBody.bounds.min,
			terrainBody.position
		);
		Matter.Body.setPosition(terrainBody, {
			x: Math.abs(centerOfMass.x) + this.x,
			y: Math.abs(centerOfMass.y) + this.y - this.padding,
		});
	}

	update() {}
}
