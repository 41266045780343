import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
declare var init: () => {};
@Component({
	selector: 'app-fon-bet-widget',
	standalone: true,
	imports: [],
	template: ` <div id="registrationContainer"></div> `,
	styleUrl: './fon-bet-widget.component.scss',
})
export class FonBetWidgetComponent implements AfterViewInit {
	@Input() code: string;
	ngAfterViewInit() {
		init();
	}
}
