import { Routes } from '@angular/router';
import { StartPageComponent } from '@pages/start-page/start-page.component';
import { OnboardingPageComponent } from '@pages/onboarding-page/onboarding-page.component';
import { MainPageComponent } from '@pages/main-page/main-page.component';
import { GamePageComponent } from '@pages/game-page/game-page.component';
import { SavePageComponent } from '@pages/save-page/save-page.component';
import { SaveFormPageComponent } from '@pages/save-form-page/save-form-page.component';
import { RegistrationPageComponent } from '@pages/registration-page/registration-page.component';
import { CongratulationsPageComponent } from '@pages/congratulations-page/congratulations-page.component';
import { FonbetPageComponent } from '@pages/fonbet-page/fonbet-page.component';
import { userScoreGuard } from '@src/app/guards/user-score.guard';
import { levelGuard } from '@src/app/guards/level.guard';

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: StartPageComponent,
	},
	{
		path: 'onboarding',
		component: OnboardingPageComponent,
		data: { animation: 'onboarding', showFooter: false },
	},
	{
		path: 'main',
		component: MainPageComponent,
		data: { animation: 'main', showFooter: true },
	},
	{
		path: 'game/:level',
		component: GamePageComponent,
		data: { animation: 'game', showFooter: false },
		canActivate: [levelGuard],
	},
	{
		path: 'save',
		component: SavePageComponent,
		data: { animation: 'save', showFooter: true },
		canActivate: [userScoreGuard],
	},
	{
		path: 'save-form',
		component: SaveFormPageComponent,
		data: { animation: 'save-form', showFooter: true },
		canActivate: [userScoreGuard],
	},
	{
		path: 'registration',
		component: RegistrationPageComponent,
		data: { animation: 'registration', showFooter: true },
		canActivate: [userScoreGuard],
	},
	{
		path: 'congratulations',
		component: CongratulationsPageComponent,
		data: { animation: 'congratulations', showFooter: true },
		canActivate: [userScoreGuard],
	},
	{
		path: 'fonbet',
		component: FonbetPageComponent,
		data: { animation: 'fonbet', showFooter: true },
		canActivate: [userScoreGuard],
	},
	{
		path: '**',
		redirectTo: '',
	},
];
