<div class="save-form">
	<div class="save-form__back">
		<a routerLink="/main">< Назад</a>
	</div>
	<form class="save-form__form" [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="save-form__title"
			>Впиши своё имя и номер телефона, <br />
			чтобы участвовать в розыгрыше!</div
		>
		<div class="save-form__field">
			<div class="save-form__label">ФИО</div>
			<input
				type="text"
				class="save-form__input"
				[ngClass]="{ _error: nameControl.errors }"
				placeholder="Ваше имя"
				formControlName="fio" />
		</div>
		<div class="save-form__field">
			<div class="save-form__label">Номер телефона</div>
			<input
				type="text"
				class="save-form__input"
				[ngClass]="{ _error: telephoneControl.errors }"
				mask="+0 (000) 000-00-00"
				placeholder="+7 ___ ___ __ __"
				formControlName="phone" />
		</div>
		<div class="checkbox">
			<input id="html2" type="checkbox" formControlName="policy" />
			<label for="html2"
				><p
					>Настоящим даю свое <a href="https://fon.bet/pages/consent/">согласие</a> на обработку персональных
					данных ООО «ФОНКОР», <a href="https://fon.bet/official-documents/beeline/">согласие</a> на обработку сведений об
					оказанных операторами услугах связи и передачу сведений ООО «ФОНКОР»,
					а также подтверждаю, что ознакомлен с <a href="#">правилами</a></p
				></label
			>
		</div>
		<button [disabled]="form.invalid" class="btn _red _large" type="submit"
			>Отправить</button
		>
	</form>
</div>
