<div class="onboarding">
  <ng-container *ngTemplateOutlet="currentTemplate()"></ng-container>

  <ng-template #firstSlide>
    <div class="onboarding__slider">
      <swiper-container init="false" appSwiper [extendConfig]="config">
        @for (slide of slides; track slide) {
        <swiper-slide>
          <div class="onboarding__slide">
            <div class="onboarding__image">
              <img
                [ngSrc]="slide"
                alt="image"
                width="280"
                height="280"
                priority />
            </div>
          </div>
        </swiper-slide>
        }
      </swiper-container>
      <button class="slider-btn _prev" id="slidePrev"></button>
      <button class="slider-btn" id="slideNext"></button>
    </div>
    <div class="onboarding__dark">
      <img class="onboarding__decor" src="./assets/images/onboarding-decor.png" alt="decor">
      <p class="onboarding__text"> <strong>Привет, болельщик ФК «Акрон»!</strong> <br><br>
        Наш ретро-автобус <strong>ЛамПАЗ</strong> отправляется в путь, чтобы доставить команду на поле, но он не сможет добраться без твоей помощи... Бери управление ЛамПАЗом в свои руки! Собирай монеты и доставь любимый клуб до стадионов как можно быстрее!
      </p>
      <button class="btn _red _medium" (click)="nextTemplate()">Хорошо!</button>
    </div>
  </ng-template>

  <ng-template #secondSlide>
    <app-modal-prize-description [static]="true" (nextTemplate)="nextTemplate()"></app-modal-prize-description>
  </ng-template>

  <ng-template #thirdSlide>
    <img class="onboarding__longImage" src="./assets/images/onboarding-long-image.png" alt="image">
    <div class="onboarding__dark onboarding__slide-third">
      <img class="onboarding__decor" src="./assets/images/onboarding-decor.png" alt="decor">
      <div class="onboarding__text">Чем быстрее ты пройдешь каждый уровень и чем больше FON-коинов соберешь, тем больше очков заработаешь!
        <br> <br>
        Соревнуйся с другими болельщиками по набранным очкам — Топ-10 получит мерч от Акрон х FONBET!
      </div>
      <div class="onboarding__dark _background">
        <div class="onboarding__textWrapper">
          <div class="onboarding__text">Когда поймешь, что набрал свой максимум за все уровни — нажми на дискету, чтобы зафиксировать результат в таблице лидеров.</div>
          <div class="save-btn"></div>
        </div>
        <a routerLink="/main" class="btn _red _medium" id="onboardingBtn" >Круто!</a>
      </div>
    </div>
  </ng-template>
<!--  <app-footer [showFooter]="currentIndex() !== 1"></app-footer>-->
</div>
