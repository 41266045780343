import { Component, inject, Input } from '@angular/core';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	standalone: true,
	imports: [],
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss',
})
export class FooterComponent {
	@Input() showFooter: boolean = true;
	private modal: ModalService = inject(ModalService);
	private router: Router = inject(Router);

	openGiftModal() {
		this.modal.openModal(ModalType.PRIZE_DESCRIPTION);
	}

	toOnboarding() {
		this.router.navigate(['/onboarding']);
	}
}
