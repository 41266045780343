import { Injectable } from '@angular/core';
import { User } from '@interfaces/user.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { LevelData } from '@interfaces/level-data.interface';
import { UserInfo } from '@interfaces/user-info.interface';

@Injectable({
	providedIn: 'root',
})
export class StateService {
	private _user: User = {
		user: { fio: null, register: null, phone: null },
		levels: [],
	};
	private _levelId$ = new BehaviorSubject<number>(1);
	public onboardingPassed: boolean = false;

	constructor() {}

	public get levelId$(): Observable<number> {
		return this._levelId$.asObservable();
	}

	public get levelId(): number {
		return this._levelId$.getValue();
	}

	public set levelId(value: number) {
		this._levelId$.next(value);
	}

	public get user(): User {
		return this._user;
	}

	public set user(value: User) {
		this._user = value;
	}

	public get userInfo(): UserInfo {
		return this._user.user;
	}

	public set userInfo(value: Partial<UserInfo>) {
		this._user.user.fio = value.fio;
		this._user.user.phone = Number(value.phone);
	}

	public set userRegistration(value: boolean) {
		this._user.user.register = `${value ? 'ya-s-fonbet' : 'ya-ne-s-fon-bet'}`;
	}

	public set userScore(data: LevelData) {
		const level = this._user.levels[data.level - 1];
		if (!level) {
			this._user.levels.push(data);
		} else {
			const currentScore = this._user.levels[data.level - 1].score;
			if (currentScore > data.score) {
				return;
			} else {
				this._user.levels[data.level - 1].score = data.score;
			}
		}
	}

	public get userScore(): number {
		return this._user.levels.reduce((a, b) => a + b.score, 0);
	}

	public getUserScoreForLevel(level: number): number {
		const levelData = this._user.levels[level - 1];
		if (levelData) {
			return levelData.score;
		} else {
			return 0;
		}
	}

	public incrementLevelId(level: number): void {
		console.log('incrementLevelId: ', level, this.levelId);
		if (this.levelId === level) {
			this._levelId$.next(this.levelId + 1);
		} else {
			return;
		}
	}
}
