import { Type } from '@angular/core';
import { ModalLevelFinishComponent } from '@modal/components/modal-level-finish/modal-level-finish.component';
import { ModalPauseComponent } from '@modal/components/modal-pause/modal-pause.component';
import { ModalLoaderComponent } from '@modal/components/modal-loader/modal-loader.component';
import { ModalGameOnboardingComponent } from '@modal/components/modal-game-onboarding/modal-game-onboarding.component';
import { ModalPrizeDescriptionComponent } from '@modal/components/modal-prize-description/modal-prize-description.component';

export enum ModalType {
	// пауза игры
	PAUSE = 'PAUSE',
	// игровой онбоардинг
	GAME_ONBOARDING = 'GAME_ONBOARDING',
	//успешное завершение уровня
	LEVEL_FINISHED = 'LEVEL_FINISHED',
	//прелоадер
	LOADER = 'LOADER',
	//прелоадер
	PRIZE_DESCRIPTION = 'PRIZE_DESCRIPTION',
}

export type ModalComponentUnion =
	| ModalPauseComponent
	| ModalGameOnboardingComponent
	| ModalLoaderComponent
	| ModalPrizeDescriptionComponent
	| ModalLevelFinishComponent;

export const modalTypeMapper: Record<ModalType, Type<ModalComponentUnion>> = {
	[ModalType.PAUSE]: ModalPauseComponent,
	[ModalType.GAME_ONBOARDING]: ModalGameOnboardingComponent,
	[ModalType.LEVEL_FINISHED]: ModalLevelFinishComponent,
	[ModalType.LOADER]: ModalLoaderComponent,
	[ModalType.PRIZE_DESCRIPTION]: ModalPrizeDescriptionComponent,
};
