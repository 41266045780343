<div class="prize-description prize-description__dark _padding12">
  <div class="prize-description__list">
    <div class="prize-description__item">
      <div class="prize-description__number">1</div>
      <p class="prize-description__text">Проходи уровни быстрее остальных <br> и старайся заработать на каждом <br> как можно больше очков</p>
    </div>
    <div class="prize-description__item">
      <div class="prize-description__number">2</div>
      <p class="prize-description__text">Сделай ставку от <strong>1000 ₽</strong> и коэф. <br> от <strong>1.50</strong> на событие из категории <br> «Футбол» до <strong>30 ноября</strong> 2024.</p>
    </div>
    <div class="prize-description__item">
      <div class="prize-description__number">3</div>
      <p class="prize-description__text">Ищи себя в списке <br> победителей 30 ноября 2024!</p>
    </div>
  </div>
  <table class="prize-description__table">
    <tr>
      <th>Заработано <br> очков</th>
      <th>Сумма <br> фрибетов</th>
    </tr>
    <tr>
      <td>180 000</td>
      <td>1 200 000</td>
    </tr>
    <tr>
      <td>150 000</td>
      <td>800 000</td>
    </tr>
    <tr>
      <td>120 000</td>
      <td>500 000</td>
    </tr>
    <tr>
      <td>60 000</td>
      <td>300 000</td>
    </tr>
    <tr>
      <td>15 000</td>
      <td>200 000</td>
    </tr>
  </table>
  <button class="btn _red _medium second-slide-btn" (click)="close()">Понял</button>
  <p class="prize-description__miniText">*приз будет разделен на равные части между всеми участниками, прошедшими соответствующее
    необходимое количество уровней игры. C подробными условиями можно ознакомиться <a href="#">в правилах акции</a>.</p>
</div>
