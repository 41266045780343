import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	inject,
	OnInit,
	signal,
	TemplateRef,
	ViewChild,
	WritableSignal,
} from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { Router, RouterLink } from '@angular/router';
import { FooterComponent } from '@components/footer/footer.component';
import { ModalPrizeDescriptionComponent } from '@modal/components/modal-prize-description/modal-prize-description.component';

@Component({
	selector: 'app-onboarding-page',
	standalone: true,
	imports: [
		NgTemplateOutlet,
		SwiperDirective,
		NgOptimizedImage,
		RouterLink,
		FooterComponent,
		ModalPrizeDescriptionComponent,
	],
	templateUrl: './onboarding-page.component.html',
	styleUrl: './onboarding-page.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingPageComponent implements OnInit {
	@ViewChild('firstSlide', { static: true }) firstSlide: TemplateRef<any>;
	@ViewChild('secondSlide', { static: true }) secondSlide: TemplateRef<any>;
	@ViewChild('thirdSlide', { static: true }) thirdSlide: TemplateRef<any>;
	public slides: string[] = [
		'./assets/images/onboarding-1.png',
		'./assets/images/onboarding-2.png',
		'./assets/images/onboarding-3.png',
	];
	public currentTemplate: WritableSignal<TemplateRef<any>> = signal(null);
	public showFooter: WritableSignal<boolean> = signal(true);
	public config: SwiperOptions = {
		pagination: true,
	};
	public currentIndex: WritableSignal<number> = signal(0);
	private templates: TemplateRef<any>[] = [];

	public ngOnInit() {
		this.templates = [this.firstSlide, this.secondSlide, this.thirdSlide];
		this.currentTemplate.set(this.templates[this.currentIndex()]);
	}

	public nextTemplate() {
		if (this.currentIndex() === this.templates.length - 1) {
			this.currentIndex.set(0);
		} else {
			this.currentIndex.update(value => value + 1);
		}
		this.currentTemplate.set(this.templates[this.currentIndex()]);
	}
}
