import { Component, HostListener } from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationStart,
	Router,
	RouterOutlet,
} from '@angular/router';
import {
	animate,
	query,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { SmartModalContainerComponent } from '@modal/components/smart-modal-container/smart-modal-container.component';
import { FooterComponent } from '@components/footer/footer.component';
import { filter, map, mergeMap, Observable, startWith, switchMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import * as eruda from 'eruda';
import { environment } from '@env/environment';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterOutlet,
		SmartModalContainerComponent,
		FooterComponent,
		AsyncPipe,
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [
		trigger('routeAnimations', [
			transition('* <=> *', [
				style({ position: 'relative' }),
				query(
					':enter, :leave',
					[
						style({
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							width: '100%',
							height: '100%',
						}),
					],
					{ optional: true }
				),
				query(':enter', [style({ opacity: 0 })], { optional: true }),
				query(
					':leave',
					[style({ opacity: 1 }), animate('.3s', style({ opacity: 0 }))],
					{ optional: true }
				),
				query(':enter', [animate('.3s', style({ opacity: 1 }))], {
					optional: true,
				}),
			]),
		]),
	],
})
export class AppComponent {
	public maxHeight: number;
	public maxWidth: number;
	public isDesktop: boolean = false;
	public showFooter$: Observable<boolean>;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.updateSize();
		this.showFooter$ = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => this.getChild(this.activatedRoute)),
			mergeMap(route => route.data),
			map(data => data['showFooter'])
		);
	}

	@HostListener('window:resize', ['$event'])
	public onResize() {
		this.updateSize();
	}

	// Функция для получения самого глубокого дочернего маршрута
	private getChild(route: ActivatedRoute): ActivatedRoute {
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route;
	}

	public prepareRoute(outlet: RouterOutlet) {
		return {
			value:
				outlet &&
				outlet.activatedRouteData &&
				outlet.activatedRouteData['animation'],
			params: { padding: this.isDesktop ? '7px 0px' : '0px' },
		};
	}

	private updateSize(): void {
		this.isDesktop = window.innerWidth > 585;
		this.maxHeight = window.innerHeight;
		this.maxWidth = window.innerWidth;
	}
}
