import Car from '@src/app/ts/models/car';

export default class Gas {
	private leftPedal!: Phaser.GameObjects.Image;
	private rightPedal!: Phaser.GameObjects.Image;
	private readonly pedalOffsetY: number = 10; // Сколько пикселей педаль смещается при нажатии

	// Сохранение предыдущего состояния педалей
	private previousLeft: boolean = false;
	private previousRight: boolean = false;

	constructor(scene: Phaser.Scene, car: Car) {
		// Создание педали движения назад (влево)
		this.leftPedal = scene.add
			.image(20, scene.cameras.main.height - 20, 'backwards')
			.setScrollFactor(0)
			.setOrigin(0, 1)
			.setScale(0.3)
			.setInteractive()
			.on('pointerdown', () => {
				car.gas.left = true;
				this.pressPedal(this.leftPedal);
			})
			.on('pointerup', () => {
				car.gas.left = false;
				this.releasePedal(this.leftPedal);
			})
			.on('pointerout', () => {
				car.gas.left = false;
				this.releasePedal(this.leftPedal);
			});

		// Создание педали газа (вправо)
		this.rightPedal = scene.add
			.image(
				scene.cameras.main.width - 20,
				scene.cameras.main.height - 20,
				'gas'
			)
			.setScrollFactor(0)
			.setOrigin(1, 1)
			.setScale(0.3)
			.setInteractive()
			.on('pointerdown', () => {
				car.gas.right = true;
				this.pressPedal(this.rightPedal);
			})
			.on('pointerup', () => {
				car.gas.right = false;
				this.releasePedal(this.rightPedal);
			})
			.on('pointerout', () => {
				car.gas.right = false;
				this.releasePedal(this.rightPedal);
			});
	}

	/**
	 * Метод, вызываемый каждый кадр для обновления состояния педалей.
	 * @param car Ссылка на объект Car.
	 */
	public update(car: Car): void {
		// Проверка изменения состояния левой педали
		if (car.gas.left && !this.previousLeft) {
			this.pressPedal(this.leftPedal);
		} else if (!car.gas.left && this.previousLeft) {
			this.releasePedal(this.leftPedal);
		}

		// Проверка изменения состояния правой педали
		if (car.gas.right && !this.previousRight) {
			this.pressPedal(this.rightPedal);
		} else if (!car.gas.right && this.previousRight) {
			this.releasePedal(this.rightPedal);
		}

		// Обновление предыдущего состояния
		this.previousLeft = car.gas.left;
		this.previousRight = car.gas.right;
	}

	/**
	 * Анимирует педаль при нажатии.
	 * @param pedal Педаль, которую нужно анимировать.
	 */
	private pressPedal(pedal: Phaser.GameObjects.Image): void {
		// Остановить все текущие твины для педали, чтобы избежать наложения
		pedal.scene.tweens.killTweensOf(pedal);

		// Создать новый твин для нажатия педали
		pedal.scene.tweens.add({
			targets: pedal,
			y: pedal.y + this.pedalOffsetY, // Смещение вниз
			duration: 100, // Длительность анимации в миллисекундах
			ease: 'Cubic.easeOut',
		});
	}

	/**
	 * Анимирует педаль при отпускании.
	 * @param pedal Педаль, которую нужно анимировать.
	 */
	private releasePedal(pedal: Phaser.GameObjects.Image): void {
		// Остановить все текущие твины для педали, чтобы избежать наложения
		pedal.scene.tweens.killTweensOf(pedal);

		// Создать новый твин для возврата педали в исходное положение
		pedal.scene.tweens.add({
			targets: pedal,
			y: pedal.y - this.pedalOffsetY, // Возврат на исходную позицию
			duration: 100, // Длительность анимации в миллисекундах
			ease: 'Cubic.easeOut',
		});
	}
}
