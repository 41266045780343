<div class="page registration-page">
  <img class="page__cloud" src="./assets/images/main-cloud.png" alt="cloud">
  <div class="page__busWrapper">
    <img class="page__bus" src="./assets/images/bus.png" alt="bus">
    <div class="page__pointer">
      <span>Конец</span>
    </div>
  </div>
  <div class="page__dark">
    <img class="page__smallDecor" src="./assets/images/small-decor.png" alt="decor">
    <div class="registration-page__title">Для участия в розыгрыше <br>
      ты должен быть клиентом <b>FONBET</b>.</div>
    <div class="registration-page__btns">
      <a class="btn _red _large _uppercase" routerLink="/congratulations">Я уже с FONBET</a>
      <a class="btn _white _large _uppercase" routerLink="/fonbet">Регистрация</a>
    </div>
    <div class="registration-page__text">Зарегистрируйся и получи <br>
      фрибет до <b>15 000 ₽</b></div>
  </div>
</div>
