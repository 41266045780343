import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StateService } from '@services/state.service';

export const userScoreGuard: CanActivateFn = (
	route,
	state,
	stateService = inject(StateService),
	router = inject(Router)
) => {
	if (stateService.userScore > 0) {
		return true;
	} else {
		return router.navigate(['/main']);
	}
};
