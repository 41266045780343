<div class="page congratulations-page">
  <img class="page__cloud" src="./assets/images/main-cloud.png" alt="cloud">
  <div class="page__busWrapper">
    <img class="page__bus" src="./assets/images/bus.png" alt="bus">
    <div class="page__pointer">
      <span>Конец</span>
    </div>
  </div>
  <div class="page__dark">
    <img class="page__smallDecor" src="./assets/images/small-decor.png" alt="decor">
    <div class="congratulations-page__title">Поздравляем!</div>
    <div class="congratulations-page__text">Теперь ты принимаешь участие
      в розыгрыше <b>3 000 000 ₽</b> фрибетами
      и эксклюзивной коллекции мерча <b>«Дорога Яркости»</b>.</div>
    <div class="congratulations-page__text"><b>Следи за новостями в сообществах ФК «АКРОН» и FONBET!</b></div>
    <img class="congratulations-page__image" src="./assets/images/onboarding-long-image.png" alt="image">
  </div>
</div>
