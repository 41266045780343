// Progressbar.ts
import Phaser from 'phaser';

// export default class Progressbar {
// 	private scene: Phaser.Scene;
// 	private background: Phaser.GameObjects.Image;
// 	private progressFill: Phaser.GameObjects.Graphics;
// 	private icon: Phaser.GameObjects.Image;
//
// 	private totalDistance: number;
//
// 	// Максимальная ширина прогресс-бара как процент от ширины экрана
// 	private readonly maxWidthPercent: number = 0.8; // 80%
// 	private readonly height: number = 8; // Высота прогресс-бара
// 	private readonly skew: number = 3; // Наклон параллелограмма вправо
//
// 	constructor(
// 		scene: Phaser.Scene,
// 		x: number,
// 		y: number,
// 		totalDistance: number
// 	) {
// 		this.scene = scene;
// 		this.totalDistance = totalDistance;
//
// 		this.create(x, y);
// 	}
//
// 	/**
// 	 * Создает компоненты прогресс-бара.
// 	 * @param x X-координата начала прогресс-бара.
// 	 * @param y Y-координата центра прогресс-бара.
// 	 */
// 	private create(x: number, y: number): void {
// 		// Создание заднего фона (параллелограмм с уклоном вправо)
// 		this.background = this.scene.add
// 			.image(x, y, 'progress-bg') // Убедитесь, что спрайт 'progress-bg' загружен
// 			.setOrigin(0, 0.5) // Привязка к левому центру
// 			.setDisplaySize(this.getMaxWidth(), this.height) // Установка размера
// 			.setDepth(0);
//
// 		// Создание полосы прогресса (красная)
// 		// this.progressFill.fillStyle(0xff0000, 1); // Красный цвет
// 		this.progressFill = this.scene.add.graphics().setDepth(1);
//
// 		this.drawParallelogram(150, 150, 0, this.height - 2); // Начальная ширина 0
// 		// this.progressFill = this.scene.add
// 		// 	.image(x, y - this.height / 2, 'progress-fill')
// 		// 	.setOrigin(0, 0.5)
// 		// 	.setDisplaySize(0, this.height - 2);
// 		// Создание иконки прогресса
// 		this.icon = this.scene.add
// 			.image(x, y, 'progress-icon') // Убедитесь, что спрайт 'progress-icon' загружен
// 			.setOrigin(0.5, 0.5) // Центрирование иконки
// 			.setDisplaySize(25, 17)
// 			.setDepth(2); // Установка размера иконки
//
// 		// Иконка изначально находится в начале прогресс-бара
// 		this.icon.setX(x);
// 	}
//
// 	/**
// 	 * Вычисляет максимальную ширину прогресс-бара на основе ширины экрана.
// 	 * @returns Максимальная ширина прогресс-бара.
// 	 */
// 	private getMaxWidth(): number {
// 		const screenWidth = this.scene.cameras.main.width;
// 		return screenWidth * this.maxWidthPercent;
// 	}
//
// 	/**
// 	 * Рисует параллелограмм на графике.
// 	 * @param x X-координата начала параллелограмма.
// 	 * @param y Y-координата начала параллелограмма.
// 	 * @param width Ширина параллелограмма.
// 	 * @param height Высота параллелограмма.
// 	 */
// 	private drawParallelogram(
// 		x: number,
// 		y: number,
// 		width: number,
// 		height: number
// 	): void {
// 		// this.progressFill.clear();
// 		this.progressFill.fillStyle(0xff0000, 1); // Красный цвет
//
// 		// Рисуем параллелограмм
// 		this.progressFill.beginPath();
// 		this.progressFill.moveTo(x, y);
// 		this.progressFill.lineTo(x + width, y);
// 		this.progressFill.lineTo(x + width - this.skew, y + height);
// 		this.progressFill.lineTo(x - this.skew, y + height);
// 		this.progressFill.closePath();
// 		this.progressFill.fillPath();
// 		this.progressFill.setDepth(1);
// 	}
//
// 	/**
// 	 * Устанавливает прогресс и обновляет визуальные элементы.
// 	 * @param currentDistance Пройденное расстояние.
// 	 */
// 	private setProgress(currentDistance: number): void {
// 		// Вычисляем процент пройденного пути
// 		const percent = Phaser.Math.Clamp(
// 			currentDistance / this.totalDistance,
// 			0,
// 			1
// 		);
//
// 		// Вычисление новой ширины параллелограмма
// 		const newWidth = this.getMaxWidth() * percent;
//
// 		// Рисуем новый параллелограмм с обновленной шириной
//
// 		// Перемещение иконки в соответствии с прогрессом
// 		const iconX = newWidth - this.skew;
// 		this.icon.setX(iconX);
// 	}
//
// 	/**
// 	 * Обновляет прогресс-бар на основе пройденного расстояния.
// 	 * @param currentDistance Пройденное расстояние.
// 	 */
// 	public update(currentDistance: number): void {
// 		this.setProgress(currentDistance);
//
// 		// Вычисление процента прогресса
// 		const progressPercent = Phaser.Math.Clamp(
// 			currentDistance / this.totalDistance,
// 			0,
// 			1
// 		);
//
// 		// Вычисление новой ширины полосы прогресса
// 		const newWidth = this.getMaxWidth() * progressPercent;
//
// 		// Очистка предыдущей графики полосы прогресса
// 		// this.progressFill.clear();
// 		this.drawParallelogram(
// 			this.background.x + 6,
// 			this.background.y - 3,
// 			newWidth,
// 			this.height - 2
// 		);
//
// 		// Перерисовка полосы прогресса с новой шириной
// 		// this.progressFill.fillStyle(0xff0000, 1); // Красный цвет
// 		// this.progressFill.fillRect(
// 		// 	this.background.x,
// 		// 	this.background.y - this.height / 2,
// 		// 	newWidth,
// 		// 	this.height
// 		// );
//
// 		// Перемещение иконки в соответствии с прогрессом
// 		this.icon.setX(this.background.x + newWidth);
// 	}
// }
// Progressbar.ts

export default class Progressbar {
	private scene: Phaser.Scene;
	private background: Phaser.GameObjects.Image;
	private progressFill: Phaser.GameObjects.Graphics;
	private icon: Phaser.GameObjects.Image;

	private totalDistance: number;

	// Максимальная ширина прогресс-бара как процент от ширины экрана
	private readonly maxWidthPercent: number = 0.8; // 80%
	private readonly height: number = 8; // Высота прогресс-бара
	private readonly skew: number = 3; // Наклон параллелограмма вправо

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		totalDistance: number
	) {
		this.scene = scene;
		this.totalDistance = totalDistance;

		this.create(x, y);
	}

	/**
	 * Создает компоненты прогресс-бара.
	 * @param x X-координата начала прогресс-бара.
	 * @param y Y-координата центра прогресс-бара.
	 */
	private create(x: number, y: number): void {
		// Создание заднего фона (параллелограмм с уклоном вправо)
		this.background = this.scene.add
			.image(x, y, 'progress-bg') // Убедитесь, что спрайт 'progress-bg' загружен
			.setOrigin(0, 0.5) // Привязка к левому центру
			.setDisplaySize(this.getMaxWidth(), this.height) // Установка размера
			.setDepth(0);

		// Создание полосы прогресса (красная)
		this.progressFill = this.scene.add.graphics().setDepth(1);

		// Начальная ширина 0
		this.drawParallelogram(
			this.background.x,
			this.background.y - this.height / 2,
			0,
			this.height
		);

		// Создание иконки прогресса
		this.icon = this.scene.add
			.image(x, y, 'progress-icon') // Убедитесь, что спрайт 'progress-icon' загружен
			.setOrigin(0.5, 0.5) // Центрирование иконки
			.setDisplaySize(25, 17)
			.setDepth(2); // Установка размера иконки

		// Иконка изначально находится в начале прогресс-бара
		this.icon.setX(x);
	}

	/**
	 * Вычисляет максимальную ширину прогресс-бара на основе ширины экрана.
	 * @returns Максимальная ширина прогресс-бара.
	 */
	private getMaxWidth(): number {
		const screenWidth = this.scene.cameras.main.width;
		return screenWidth * this.maxWidthPercent;
	}

	/**
	 * Рисует параллелограмм на графике.
	 * @param x X-координата начала параллелограмма.
	 * @param y Y-координата начала параллелограмма.
	 * @param width Ширина параллелограмма.
	 * @param height Высота параллелограмма.
	 */
	private drawParallelogram(
		x: number,
		y: number,
		width: number,
		height: number
	): void {
		this.progressFill.clear();
		this.progressFill.fillStyle(0xff0000, 1); // Красный цвет

		// Рисуем параллелограмм
		this.progressFill.beginPath();
		this.progressFill.moveTo(x, y);
		this.progressFill.lineTo(x + width, y);
		this.progressFill.lineTo(x + width - this.skew, y + height);
		this.progressFill.lineTo(x - this.skew, y + height);
		this.progressFill.closePath();
		this.progressFill.fillPath();
	}

	/**
	 * Обновляет прогресс-бар на основе пройденного расстояния.
	 * @param currentDistance Пройденное расстояние (от 0 до totalDistance).
	 */
	public update(currentDistance: number): void {
		// Вычисляем процент пройденного пути
		const percent = Phaser.Math.Clamp(
			currentDistance / this.totalDistance,
			0,
			1
		);

		// Вычисление новой ширины параллелограмма
		const newWidth = this.getMaxWidth() * percent;

		// Рисуем новый параллелограмм с обновленной шириной
		this.drawParallelogram(
			this.background.x,
			this.background.y - this.height / 2,
			newWidth,
			this.height
		);

		// Перемещение иконки в соответствии с прогрессом
		this.icon.setX(this.background.x + newWidth);
	}
}
