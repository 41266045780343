// TimeCounter.ts
import Phaser from 'phaser';
import { GameNames } from '@enums/game.enum';

export default class TimeCounter extends Phaser.GameObjects.Container {
	private background: Phaser.GameObjects.Image;
	private icon: Phaser.GameObjects.Image;
	private text: Phaser.GameObjects.Text;
	private timerEvent: Phaser.Time.TimerEvent;
	public elapsedTime: number = 0; // Время в секундах
	public formatedTime: string = ''; // Время в секундах

	constructor(scene: Phaser.Scene, x: number, y: number) {
		super(scene, x, y);

		// Создание заднего фона
		this.background = scene.add.image(0, 0, 'time-bg'); // Убедитесь, что спрайт 'time-bg' загружен
		this.background.setOrigin(0, 0.5); // Настройте точку привязки по необходимости

		// Создание иконки часов
		this.icon = scene.add.image(25, 0, 'time'); // Убедитесь, что спрайт 'time' загружен
		this.icon.setOrigin(0.5, 0.5);
		this.icon.setDisplaySize(30, 30); // Настройте размер по необходимости

		// Создание текстового поля
		this.text = scene.add.text(45, 1, '00:00.0', {
			fontSize: '14px',
			color: '#ffffff',
			fontStyle: 'bold',
		});
		this.text.setOrigin(0, 0.5);

		// Добавление элементов в контейнер
		this.add([this.background, this.icon, this.text]);

		// Добавление контейнера в сцену
		scene.add.existing(this);

		// Запуск таймера
		this.startTimer();
	}

	/**
	 * Запускает таймер
	 */
	public startTimer(): void {
		this.timerEvent = this.scene.time.addEvent({
			delay: 100, // Обновление каждые 100 мс (0.1 сек)
			callback: this.updateTime,
			callbackScope: this,
			loop: true,
		});
	}

	/**
	 * Останавливает таймер
	 */
	public stopTimer(): void {
		if (this.timerEvent) {
			this.timerEvent.remove(false);
		}
	}

	/**
	 * Сбрасывает таймер
	 */
	public resetTimer(): void {
		this.stopTimer();
		this.elapsedTime = 0;
		this.text.setText(this.formatTime(this.elapsedTime));
		this.startTimer();
	}

	/**
	 * Обновляет прошедшее время
	 */
	private updateTime(): void {
		this.elapsedTime += 0.1; // Увеличиваем время на 0.1 сек
		this.text.setText(this.formatTime(this.elapsedTime));

		// Сохранение времени в миллисекундах в registry Phaser
		const elapsedTimeMs = Math.floor(this.elapsedTime * 1000);
		this.scene.registry.set('elapsedTime', elapsedTimeMs);
	}

	/**
	 * Форматирует время в строку по паттерну MM:SS.T
	 * @param time Время в секундах
	 * @returns Отформатированная строка
	 */
	private formatTime(time: number): string {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		const tenths = Math.floor((time % 1) * 10);

		const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
		this.formatedTime = `${minutesStr}:${secondsStr}.${tenths}`;

		return `${minutesStr}:${secondsStr}.${tenths}`;
	}
}
