import { Component, inject, Input, OnInit } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { StateService } from '@services/state.service';
import { GameService } from '@services/game.service';
import { LevelFinish } from '@interfaces/level-finish.interface';
import { ApiService } from '@services/api.service';
import { LevelRecord } from '@interfaces/level-record.interface';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-modal-level-finish',
	templateUrl: './modal-level-finish.component.html',
	styleUrl: './modal-level-finish.component.scss',
	standalone: true,
	imports: [AsyncPipe],
})
export class ModalLevelFinishComponent
	extends ModalBaseComponent
	implements OnInit
{
	@Input() data: LevelFinish;
	private state = inject(StateService);
	private api = inject(ApiService);
	private gameService = inject(GameService);
	public levelRecord$: Observable<LevelRecord>;
	ngOnInit() {
		this.levelRecord$ = this.api.getUserPlaceForLevel(
			this.data.level,
			this.data.score
		);
	}

	nextLevel() {
		this.data.game.destroy(true);
		this.state.userScore = {
			coins: this.data.coins,
			level: this.data.level,
			score: this.data.score,
			time: this.data.time,
		};
		this.state.incrementLevelId(this.data.level);
		let route: string;
		if (this.data.level === 5) {
			route = '/save';
		} else {
			route = '/main';
		}
		this.closeAndNavigate(route);
	}

	restart() {
		this.gameService.prepareGame(this.data.level);
	}
}
