<div class="modal-pause">
  <div class="modal-pause__head">
    <div class="modal-pause__title">ПАУЗА</div>
    <button class="close-btn" (click)="resume()"></button>
  </div>
  <div class="modal-pause__inner">
    <img class="modal-pause__decor" src="./assets/images/onboarding-decor.png" alt="decor">
    <div class="modal-pause__text">Перезапустить уровень?</div>
    <div class="modal-pause__btns">
      <button
        class="btn _large _red"
        (click)="restart()">Перезапустить</button>
      <button
        class="btn _large _black"
        (click)="closeGame()">Вернуться в меню</button>
    </div>
  </div>
</div>
