import Car from '@src/app/ts/models/car';
export default class Speedometer {
	private scene: Phaser.Scene;
	private speedometerBg!: Phaser.GameObjects.Image;
	private speedometer!: Phaser.GameObjects.Image;
	private needle!: Phaser.GameObjects.Image;

	// Параметры спидометра
	private readonly maxDisplaySpeed: number = 10; // Максимальная скорость для отображения
	private readonly minAngle: number = -180; // Минимальный угол стрелки (в градусах)
	private readonly maxAngle: number = 30; // Максимальный угол стрелки (в градусах)

	constructor(scene: Phaser.Scene, x: number, y: number) {
		this.scene = scene;
		this.create(x, y);
	}

	/**
	 * Создает компоненты спидометра.
	 * @param x X-координата центра спидометра.
	 * @param y Y-координата центра спидометра.
	 */
	private create(x: number, y: number): void {
		// Создание заднего фона спидометра
		const scale = 1.3;
		this.speedometerBg = this.scene.add
			.image(x, y, 'speed')
			.setScrollFactor(0)
			.setOrigin(0.5, 0.5)
			.setScale(scale);

		// Создание спидометра (дополнительный фон или рамка)
		// this.speedometer = this.scene.add
		// 	.image(x, y, 'speedometer')
		// 	.setScrollFactor(0)
		// 	.setOrigin(0.5, 0.5)
		// 	.setDisplaySize(150, 150); // Настройте размер по необходимости

		// Создание стрелки спидометра
		this.needle = this.scene.add
			.image(x, y, 'arrow')
			.setScrollFactor(0)
			.setOrigin(0.5, 0.8)
			.setScale(scale);
	}

	/**
	 * Обновляет положение стрелки спидометра на основе текущей скорости.
	 * @param speed Текущая скорость автомобиля.
	 */
	public update(speed: number): void {
		// Ограничение скорости для отображения
		const displaySpeed = Phaser.Math.Clamp(speed, 0, this.maxDisplaySpeed);

		// Пропорциональное преобразование скорости в угол
		const angle = Phaser.Math.Linear(
			this.minAngle,
			this.maxAngle,
			displaySpeed / this.maxDisplaySpeed
		);

		// Плавное вращение стрелки до нового угла
		this.scene.tweens.add({
			targets: this.needle,
			angle: angle,
			duration: 200,
			ease: 'Cubic.easeOut',
			overwrite: true, // Перезаписывать текущие твины стрелки
		});
	}
}
