import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { User } from '@interfaces/user.interface';
import { LevelRecord } from '@interfaces/level-record.interface';
import { StateService } from '@services/state.service';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private _token: string;
	private api = environment.apiEndpoint;
	constructor(
		private http: HttpClient,
		private state: StateService
	) {}

	get token() {
		return this._token;
	}

	set token(value) {
		this._token = value;
	}

	public sendUserData(data: User): Observable<any> {
		return this.http.post(this.api + '/game/user', data).pipe(
			catchError(e => {
				console.error(e);
				return EMPTY;
			})
		);
	}

	public getUserPlaceForLevel(
		level: number,
		score: number
	): Observable<LevelRecord> {
		return this.http
			.get<{
				data: LevelRecord;
			}>(this.api + `/game/level?level=${level}&score=${score}`)
			.pipe(
				map(res => res.data),
				catchError(e => {
					console.error(e);
					return EMPTY;
				})
			);
	}

	public getUserPlaceForAllLevels(): Observable<LevelRecord> {
		const score = this.state.userScore;
		return this.http
			.get<{
				data: LevelRecord;
			}>(this.api + `/game/all?score=${score}`)
			.pipe(
				map(res => res.data),
				catchError(e => {
					console.error(e);
					return EMPTY;
				})
			);
	}
}
