// CoinCounter.ts
import Phaser from 'phaser';

export default class CoinCounter extends Phaser.GameObjects.Container {
	private readonly background: Phaser.GameObjects.Image;
	private readonly icon: Phaser.GameObjects.Image;
	private readonly text: Phaser.GameObjects.Text;
	private readonly totalCoins: number;
	private collectedCoins: number = 0;

	get coins(): number {
		return this.collectedCoins;
	}

	constructor(scene: Phaser.Scene, x: number, y: number, totalCoins: number) {
		super(scene, x, y);

		this.totalCoins = totalCoins;
		this.collectedCoins = 0;

		// Создание заднего фона
		this.background = scene.add.image(0, 0, 'counter-bg'); // Убедитесь, что спрайт 'coin-counter-bg' загружен
		this.background.setOrigin(0, 0.5); // Настройте по необходимости

		// Создание иконки монетки
		this.icon = scene.add.image(25, 0, 'coin'); // Убедитесь, что спрайт 'coin-icon' загружен
		this.icon.setOrigin(0.5, 0.5);
		this.icon.setDisplaySize(30, 30); // Настройте размер по необходимости

		// Создание текстового поля
		this.text = scene.add.text(50, 2, `${this.collectedCoins}`, {
			fontSize: '18px',
			color: '#ffffff',
			fontStyle: 'bold',
		});
		this.text.setOrigin(0, 0.5);

		// Добавление элементов в контейнер
		this.add([this.background, this.icon, this.text]);

		// Добавление контейнера в сцену
		scene.add.existing(this);
	}

	/**
	 * Обновляет количество оставшихся монеток
	 * @param newCount Новое количество оставшихся монеток
	 */
	public updateCount(newCount: number): void {
		this.collectedCoins = this.totalCoins - newCount;
		this.text.setText(`${this.collectedCoins}`);
	}

	/**
	 * Сбрасывает счётчик до первоначального состояния
	 */
	public reset(): void {
		this.collectedCoins = 0;
		this.text.setText(`${this.collectedCoins}`);
	}
}
