import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
	selector: 'app-registration-page',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './registration-page.component.html',
	styleUrl: './registration-page.component.scss',
})
export class RegistrationPageComponent {}
