import { ElementRef, inject, Injectable } from '@angular/core';
import { Game } from 'phaser';
import { gameConfig } from '@src/app/ts/models/game';
import {
	coefficientMap,
	getRoadSprite,
} from '@interfaces/level-config.interface';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { GameNames } from '@enums/game.enum';
import { LevelFinish } from '@interfaces/level-finish.interface';
import { StateService } from '@services/state.service';

@Injectable({
	providedIn: 'root',
})
export class GameService {
	private game: Game;
	private modal: ModalService = inject(ModalService);
	private state: StateService = inject(StateService);
	private config = gameConfig;
	private _container: HTMLElement;
	private currentLevel: number;
	set container(container: ElementRef) {
		this._container = container.nativeElement;
	}

	get container(): HTMLElement {
		return this._container;
	}
	constructor() {}

	public prepareGame(level: number = this.currentLevel) {
		if (this.game) {
			this.game.destroy(true);
		}
		this.currentLevel = level;
		this.game = new Game({
			...this.config,
			scale: {
				...this.config,
				width: this.container.offsetWidth,
				height: this.container.offsetHeight,
			},
		});
		const sprite = getRoadSprite({ level });
		this.game.registry.set(GameNames.SPRITE, sprite);
		this.game.registry.set(GameNames.LEVEL, level);
		if (this.state.onboardingPassed) {
			this.modal.closeModal();
		}
		function getMaxTextureSize(): number {
			const canvas = document.createElement('canvas');
			const gl =
				canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

			if (gl) {
				//@ts-ignore
				return gl.getParameter(gl.MAX_TEXTURE_SIZE);
			} else {
				// Если WebGL не поддерживается, можно установить значение по умолчанию
				return 2048; // Или другое подходящее значение
			}
		}

		const maxTextureSize = getMaxTextureSize();
		console.log('Максимальный размер текстуры:', maxTextureSize);
		this.eventsListener();
	}

	private eventsListener() {
		this.game.events.on('loadingComplete', () => {
			console.log('loadingComplete');
			if (!this.state.onboardingPassed) {
				this.game.pause();
				this.modal.openModal(ModalType.GAME_ONBOARDING, true, {
					data: this.game,
				});
			}
		});
		this.game.events.on('start', () => {
			console.log('start');
		});
		this.game.events.on('restart', () => {
			console.log('restart');
			this.modal.openModal(ModalType.PAUSE, true, { data: this.game });
			const mainScene = this.game.scene.getScene('MainScene');
			const guiScene = this.game.scene.getScene('GuiScene');
			mainScene.game.pause();
			guiScene.game.pause();
		});

		this.game.events.once('finish', () => {
			console.log('finish');
			this.game.pause();
			const time = Number(this.game.registry.get(GameNames.TIME));
			const formattedTime = this.game.registry.get(GameNames.FORMATTED_TIME);
			const coins = this.game.registry.get(GameNames.COINS);
			const coefficient = coefficientMap[this.currentLevel];
			console.log('level coefficient: ', coefficient);
			const timeScore = coefficient - time < 0 ? 0 : coefficient - time;
			const score = 500 + coins * 100 + timeScore;
			const currentLevel = this.currentLevel;
			const data: LevelFinish = {
				game: this.game,
				formattedTime,
				coins,
				score,
				time,
				level: currentLevel,
			};
			this.modal.openModal(ModalType.LEVEL_FINISHED, true, {
				data: data,
			});
			this.game.destroy(true);
		});
	}
}
// 1lvl= 4000-[секунды с десятымидолями]*10
// 2lvl= 6000-[секунды с десятымидолями]*10
// 3lvl= 7500-[секунды с десятымидолями]*10
// 4lvl= 9000-[секунды с десятымидолями]*10
// 5lvl= 10000-[секунды с десятымидолями]*10
//если отрицательное, то =0//
