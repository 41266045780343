import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { StateService } from '@services/state.service';
import { ApiService } from '@services/api.service';
import { LevelRecord } from '@interfaces/level-record.interface';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-save-page',
	standalone: true,
	imports: [RouterLink, AsyncPipe],
	templateUrl: './save-page.component.html',
	styleUrl: './save-page.component.scss',
})
export class SavePageComponent {
	private state: StateService = inject(StateService);
	private api: ApiService = inject(ApiService);
	public userScore: number = this.state.userScore;
	public allLevelsRecord$: Observable<LevelRecord> =
		this.api.getUserPlaceForAllLevels();
}
