import { Component, inject, OnInit } from '@angular/core';
import { StateService } from '@services/state.service';
import { ApiService } from '@services/api.service';
import { delay, take } from 'rxjs';

@Component({
	selector: 'app-congratulations-page',
	standalone: true,
	imports: [],
	templateUrl: './congratulations-page.component.html',
	styleUrl: './congratulations-page.component.scss',
})
export class CongratulationsPageComponent implements OnInit {
	private state = inject(StateService);
	private api = inject(ApiService);

	ngOnInit() {
		this.state.userRegistration = true;
		this.api
			.sendUserData(this.state.user)
			.pipe(delay(4000), take(1))
			.subscribe(() => {
				window.location.href = 'https://fon.bet';
			});
	}
}
