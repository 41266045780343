<div class="modal-level-finish">
  <img class="modal-level-finish__cloud _left" src="./assets/images/cloud-left.png" alt="cloud">
  <img class="modal-level-finish__cloud _right" src="./assets/images/cloud-right.png" alt="cloud">
  <div class="modal-level-finish__inner">
    <img class="modal-level-finish__decor" src="./assets/images/onboarding-decor.png" alt="decor">
    <img class="modal-level-finish__chess" src="./assets/images/chess-bg.png" alt="decor">
    <img class="modal-level-finish__bus" src="./assets/images/bus.png" alt="bus">
    <div class="modal-level-finish__title">Уровень пройден!</div>
    <div class="modal-level-finish__table">
      <div class="modal-level-finish__table-decor _topLeft"></div>
      <div class="modal-level-finish__table-decor _topRight"></div>
      <div class="modal-level-finish__table-decor _bottomLeft"></div>
      <div class="modal-level-finish__table-decor _bottomRight"></div>
      <div class="modal-level-finish__table-top">
        <div class="modal-level-finish__table-value">
          <img class="modal-level-finish__table-icon" src="./assets/images/time.svg" alt="time">
          <div class="modal-level-finish__table-text">{{data.formattedTime}}</div>
        </div>
        <div class="modal-level-finish__table-value">
          <img class="modal-level-finish__table-icon" src="./assets/images/fonbet-circle.svg" alt="fonbet">
          <div class="modal-level-finish__table-text">{{ data.coins }}</div>
        </div>
      </div>
      <div class="modal-level-finish__table-bottom">
        <div class="modal-level-finish__table-points">
          Итого очков: <br> <span>{{data.score}}</span>
        </div>
      </div>
    </div>
    <div class="modal-level-finish__info">
      <div class="modal-level-finish__info-item">Место: <span>#{{ (levelRecord$ | async)?.place ?? '--' }}</span></div>
      <div class="modal-level-finish__info-item">Рекорд болельщиков: <span>{{ (levelRecord$ | async)?.record ?? '--' }}</span></div>
    </div>
    <div class="modal-level-finish__btns">
      <button class="btn _red _large" (click)="nextLevel()">Далее</button>
      <button class="btn _black _large" (click)="restart()">Попробовать снова</button>
    </div>
  </div>
</div>
