export default class Restart {
	private _scene: Phaser.Scene;
	private restartContainer!: Phaser.GameObjects.Container;
	private restartButton!: Phaser.GameObjects.Image;
	private isRestarting: boolean = false; // Флаг, предотвращающий повторный рестарт

	constructor(scene: Phaser.Scene) {
		this._scene = scene;
	}

	addRestartButton() {
		// Создание заднего фона кнопки рестарта
		const background = this._scene.add
			.image(0, 0, 'restart-bg') // Убедитесь, что вы загрузили изображение с ключом 'restart-bg'
			.setOrigin(0.5, 0.5)
			.setDisplaySize(72, 48); // Настройте размер по необходимости

		// Создание кнопки рестарта поверх заднего фона
		this.restartButton = this._scene.add
			.image(0, 0, 'restart-btn')
			.setOrigin(0.5, 0.5)
			.setDisplaySize(35, 35); // Настройте размер по необходимости

		// Создание контейнера, объединяющего задний фон и кнопку
		this.restartContainer = this._scene.add.container(
			this._scene.cameras.main.width - 60,
			115 // Y-координата, настройте по необходимости
		);

		// Добавление заднего фона и кнопки в контейнер
		this.restartContainer.add([background, this.restartButton]);

		// Настройка интерактивности на кнопку рестарта
		this.restartButton
			.setInteractive({ useHandCursor: true }) // Показывать указатель руки при наведении
			.on('pointerdown', () => {
				if (!this.isRestarting) {
					this.isRestarting = true; // Установка флага рестарта
					this.spinButtonAndRestart();
				}
			});
	}

	/**
	 * Анимирует вращение кнопки и выполняет рестарт сцены после завершения анимации.
	 */
	private spinButtonAndRestart() {
		// Создание твина для вращения кнопки
		this._scene.tweens.add({
			targets: this.restartButton,
			angle: 360, // Полный оборот
			duration: 500, // Длительность вращения в миллисекундах
			ease: 'Cubic.easeOut',
			onComplete: () => {
				Restart.restart(this._scene);
				this.isRestarting = false; // Сброс флага рестарта
			},
		});
	}

	/**
	 * Статический метод для рестарта сцен.
	 * @param scene Текущая сцена Phaser.
	 */
	static restart(scene: Phaser.Scene) {
		let loadingScreen = document.getElementById('loading-screen');
		if (loadingScreen) {
			loadingScreen.style.display = 'flex';
		}
		scene.time.addEvent({
			delay: 250,
			callback: () => {
				scene.game.events.emit('restart', scene);
				// const mainScene = scene.scene.get('MainScene');
				// mainScene.scene.restart();
				// const guiScene = scene.scene.get('GuiScene');
				// guiScene.scene.restart();
			},
		});
	}
}
