<div class="main-page">
  <img class="main-page__cloud" src="./assets/images/main-cloud.png" alt="cloud">
  <div class="main-page__slider">
    <img class="main-page__bus" src="./assets/images/bus.png" alt="bus">
    <swiper-container init="false" appSwiper (slideIndex)="changeCityName($event)" >
      @for (slide of cities; track slide) {
        <swiper-slide>
          <div class="main-page__busWrapper">
            <div class="main-page__pointer">
              <span>{{slide.name}}</span>
            </div>
          </div>
        </swiper-slide>
      }
    </swiper-container>
  </div>

  <div class="main-page__dark">
    <img class="main-page__smallDecor" src="./assets/images/small-decor.png" alt="decor">
    <div class="main-page__town">
      <div class="main-page__town-top">
        <button class="slider-btn _prev" id="slidePrev"></button>
        <div class="main-page__town-title">Выезд в {{ cities[cityIndex()].directionName }}</div>
        <button class="slider-btn" id="slideNext"></button>
      </div>
      <div class="main-page__town-bottom">
        <img class="main-page__town-image" [ngSrc]="cities[cityIndex()].image" width="124" height="145" alt="players">
        <div class="main-page__town-text">{{cities[cityIndex()].description}}</div>
      </div>
    </div>
    <div class="main-page__smallTable">
      <div class="main-page__smallTable-head">
        <div class="main-page__smallTable-col">Место</div>
        <div class="main-page__smallTable-col">Мои очки</div>
        <div class="main-page__smallTable-col">Рекорд <br> болельщиков: </div>
      </div>
      <div class="main-page__smallTable-body">
        <div class="main-page__smallTable-col">{{(levelRecord$ | async)?.place ?? '--'}}</div>
        <div class="main-page__smallTable-col">{{user?.levels[cityIndex()]?.score ?? 0}}</div>
        <div class="main-page__smallTable-col">{{(levelRecord$ | async)?.record ?? '--'}}</div>
      </div>
    </div>
    <div class="main-page__btns">
      <button class="save-btn" [disabled]="userScore === 0" (click)="saveScore()"></button>
      <a class="btn _red _medium" [routerLink]="['/game', cityIndex() + 1]">Погнали</a>
    </div>
  </div>
</div>
