import { Component, inject } from '@angular/core';
import { PreloaderComponent } from '@components/preloader/preloader.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-start-page',
	standalone: true,
	imports: [PreloaderComponent],
	templateUrl: './start-page.component.html',
	styleUrl: './start-page.component.scss',
})
export class StartPageComponent {
	private router: Router = inject(Router);
	public navigateTo() {
		this.router.navigate(['/onboarding']);
	}
}
