import Gas from '@src/app/ts/models/gas';
import Restart from '@src/app/ts/models/restart';
import MainScene from '@src/app/ts/scenes/main-scene';
import Speedometer from '@src/app/ts/models/speedometer';
import Progressbar from '@src/app/ts/models/progressbar';
import Car from '@src/app/ts/models/car';
import Terrain from '@src/app/ts/models/terrain-dynamic';
import CoinCounter from '@src/app/ts/models/coin-counter';
import TimeCounter from '@src/app/ts/models/time-counter';
import { GameNames } from '@enums/game.enum';

export default class GuiScene extends Phaser.Scene {
	private gas: Gas;
	private speedometer: Speedometer;
	// Прогресс-бар
	private progressbar: Progressbar;
	private totalDistance: number = 1000; // Задайте общую дистанцию трассы
	private coinCounter: CoinCounter; // Добавляем поле для CoinCounter
	private timeCounter: TimeCounter; // Добавляем поле для CoinCounter
	private terrain: Terrain; // Добавляем поле для CoinCounter

	constructor() {
		super({ key: 'GuiScene' });
	}

	create() {
		// @ts-ignore
		const mainScene: MainScene = this.scene.get('MainScene');

		new Restart(this).addRestartButton();
		// Получение общей длины трассы из Terrain
		this.terrain = mainScene.getTerrain(); // Предполагается, что есть метод getTerrain()
		if (this.terrain) {
			this.totalDistance = this.terrain.totalLength;
		}

		// Создание педалей газа, передача ссылки на mainScene.car
		this.gas = new Gas(this, mainScene.car);
		// Добавление спидометра
		this.speedometer = new Speedometer(
			this,
			this.cameras.main.width / 2,
			this.cameras.main.height - 80
		);
		// Добавление прогресс-бара
		// Позиционируйте прогресс-бар, например, в верхней части экрана
		const progressbarX = 32; // Отступ слева
		const progressbarY = 50; // Отступ сверху
		this.progressbar = new Progressbar(
			this,
			progressbarX,
			progressbarY,
			mainScene.terrain.background.width + 50
		);
		// Добавление счётчика монеток
		const coinCounterX = 32; // Отступ слева
		const coinCounterY = 100; // Отступ сверху
		const totalCoins = mainScene.numberOfCoins; // Получаем общее количество монеток
		this.coinCounter = new CoinCounter(
			this,
			coinCounterX,
			coinCounterY,
			totalCoins
		);

		// Добавление текста для отображения собранных монеток
		// В нашем случае CoinCounter уже включает текст, поэтому дополнительный текст не требуется

		// Настройка координат и фиксирование позиции счётчика относительно камеры
		this.coinCounter.setScrollFactor(0); // Зафиксировать счётчик относительно камеры

		// Добавление счётчика времени
		const timeCounterX = 32; // Отступ слева
		const timeCounterY = 135; // Отступ сверху (можно настроить)
		this.timeCounter = new TimeCounter(this, timeCounterX, timeCounterY);

		// Фиксирование позиций элементов относительно камеры
		this.coinCounter.setScrollFactor(0); // Зафиксировать счётчик монеток относительно камеры
		this.timeCounter.setScrollFactor(0); // Зафиксировать счётчик времени относительно камеры
	}

	public startTimer() {
		// this.timeCounter.startTimer();
	}

	override update(): void {
		// @ts-ignore
		const mainScene: MainScene = this.scene.get('MainScene');
		this.gas.update(mainScene.car);
		const currentSpeed = mainScene.car.getSpeed();
		this.speedometer.update(currentSpeed);
		// Обновление прогресс-бара
		const currentDistance = this.calculateDistance(mainScene.car);
		this.progressbar.update(currentDistance);

		if (currentDistance >= this.terrain.background.width) {
			this.timeCounter.stopTimer();
			// Сохранение времени в миллисекундах в registry Phaser
			const elapsedTimeMs = Math.floor(this.timeCounter.elapsedTime * 1000);
			const formattedTime = this.timeCounter.formatedTime;
			this.registry.set(GameNames.TIME, elapsedTimeMs);
			this.registry.set(GameNames.FORMATTED_TIME, formattedTime);
			this.registry.set(GameNames.COINS, this.coinCounter.coins);
			this.sys.game.events.emit('finish');
		}
		// Обновление счётчика монеток
		const remainingCoins = mainScene.coins.length;
		this.coinCounter.updateCount(remainingCoins);
	}

	/**
	 * Вычисляет пройденную дистанцию автомобиля на основе его позиции.
	 * @param car Ссылка на объект Car.
	 * @returns Пройденная дистанция.
	 */
	private calculateDistance(car: Car): number {
		// Предполагается, что начальная позиция автомобиля по X была задана как 0
		// Если начальная позиция другая, скорректируйте вычисление
		const carPosition = car.getPosition(); // Предполагается, что есть метод getPosition()
		return Phaser.Math.Clamp(carPosition.x, 0, this.totalDistance);
	}
}
